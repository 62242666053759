import { useCallback, useEffect, useRef } from 'react';

import { useUpdateActivePortfolio } from './useUpdateActivePortfolio.hook';

const DEFAULT_UPDATE_PORTFOLIO_DELAY = 7000;

export const useUpdateActivePortfolioWithDelay = (delay = DEFAULT_UPDATE_PORTFOLIO_DELAY) => {
  const updatePortfolioTimeoutRef = useRef<NodeJS.Timeout>();
  const updateActivePortfolio = useUpdateActivePortfolio();

  const cleanUpPortfolioTimeout = useCallback(() => {
    clearTimeout(updatePortfolioTimeoutRef.current);
  }, []);

  useEffect(() => {
    return () => {
      cleanUpPortfolioTimeout();
    };
  }, [cleanUpPortfolioTimeout]);

  return useCallback(
    (keepCache?: boolean) => {
      cleanUpPortfolioTimeout();
      updatePortfolioTimeoutRef.current = setTimeout(() => {
        updateActivePortfolio(keepCache);
      }, delay);
    },
    [cleanUpPortfolioTimeout, delay, updateActivePortfolio],
  );
};
