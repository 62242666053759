import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { modifyProfileSettingAction, profileSettingsSelector, useSettingsEq } from '@store/settings';
import { MetaNotifications } from '@api/schemas/profile';

import { useNotification } from '~/shared/hooks/useNotification';
import { NotificationParams } from '~/contexts/notifications';

type Props = {
  name: string;
} & NotificationParams;

export const useMetaNotification = ({ message, placement, type, duration, name }: Props) => {
  const settings = useSelector(profileSettingsSelector, useSettingsEq.equals);
  const { openNotification } = useNotification();
  const dispatch = useDispatch();

  const notifications = useMemo(
    () =>
      pipe(
        settings,
        RD.map((meta) => meta.notifications || []),
        RD.getOrElse((): MetaNotifications => []),
      ),
    [settings],
  );

  useEffect(() => {
    pipe(
      notifications,
      A.findFirst((item) => item.name === name),
      O.map(() => {
        openNotification({
          message,
          placement,
          type,
          duration,
          key: name,
          onClose: () =>
            dispatch(
              modifyProfileSettingAction({
                notifications: notifications.filter((item) => item.name !== name),
              }),
            ),
        });
      }),
    );
  }, [dispatch, notifications, openNotification, message, placement, type, duration, name]);
};
