const postMessageCloseApp = () => {
  try {
    window.parent.postMessage(JSON.stringify({ event: 'eko-close-app' }), '*');
  } catch {
    console.error('Unable to send a post message');
  }
};

export const closeAppMessage = () => {
  postMessageCloseApp();
};
